import type { ApiResponseBaseType } from "@/types/BasicType";

export function createQueryString<T>(params: T): string {
  const urlParams = new URLSearchParams();
  for (const item in params) {
    urlParams.set(item, String(params[item]));
  }
  return `?${urlParams.toString()}`;
}

export function serializeToFormData(
  formData: FormData,
  obj: Record<string, unknown>,
  namespace = ""
): void {
  Object.entries(obj).forEach(([propertyName, propertyValue]) => {
    if (
      propertyName in obj &&
      propertyValue !== undefined &&
      propertyValue !== null &&
      propertyName[0] !== "_"
    ) {
      const formKey = namespace ? `${namespace}.${propertyName}` : propertyName;
      if (Array.isArray(propertyValue)) {
        propertyValue.forEach((element, index) => {
          if (typeof element !== "object") {
            formData.append(formKey, element);
          } else {
            const tempFormKey = `${formKey}.${index}`;
            serializeToFormData(formData, element, tempFormKey);
          }
        });
      } else if (typeof propertyValue === "object") {
        serializeToFormData(
          formData,
          propertyValue as Record<string, unknown>,
          formKey
        );
      } else {
        if (typeof propertyValue === "string") {
          if (propertyValue.length > 0) {
            formData.append(formKey, propertyValue as string | Blob);
          }
        } else {
          formData.append(formKey, propertyValue as string | Blob);
        }
      }
    }
  });
}

export function requestHandler<T = ApiResponseBaseType>(
  response: Response,
  errorMessage?: string
): Promise<T> {
  if (response.ok) {
    return response.json() as Promise<T>;
  } else if (response.status === 401) {
    location.href = "/login";
  }
  throw new Error(errorMessage ?? response.statusText);
}
